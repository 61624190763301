// eslint-disable-next-line @typescript-eslint/no-empty-function
(function (window: any, document: Document, beacon = function () {}): void {
    const load = () => {
        const firstScriptTag = document.getElementsByTagName('script')[0];
        let scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.async = !0;
        scriptTag.src = 'https://beacon-v2.helpscout.net';
        firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
    };

    const addToQueue = (method, options, data) => {
        window.Beacon.readyQueue.push({ method, options, data });
    };

    window.Beacon = beacon = addToQueue;
    beacon.readyQueue = [];
    if (document.readyState === 'complete') {
        load();
    } else {
        window.addEventListener('load', load, false);
    }
})(window, document, (window as any).Beacon);
